import axios from 'axios';
import { base_url } from '../../utils/axiosConfig';
import { toast } from 'react-toastify';

// Action Types
export const GET_CART_SUCCESS = 'GET_CART_SUCCESS';
export const GET_CART_FAILURE = 'GET_CART_FAILURE';
export const ADD_TO_CART_SUCCESS = 'ADD_TO_CART_SUCCESS';
export const ADD_TO_CART_FAILURE = 'ADD_TO_CART_FAILURE';
export const DELETE_FROM_CART_SUCCESS = 'DELETE_FROM_CART_SUCCESS';
export const DELETE_FROM_CART_FAILURE = 'DELETE_FROM_CART_FAILURE';
export const GET_CART_TOTAL = 'GET_CART_TOTAL';
export const CART_ERROR = 'CART_ERROR';
export const INCREASE_QUANTITY = 'INCREASE_QUANTITY';
export const DECREASE_QUANTITY = 'DECREASE_QUANTITY';
export const GET_PRODUCT_QUANTITY_SUCCESS = 'GET_PRODUCT_QUANTITY_SUCCESS';
export const GET_PRODUCT_QUANTITY_FAILURE = 'GET_PRODUCT_QUANTITY_FAILURE';


// Fetch cart items
export const getCart = () => async (dispatch) => {
    try {
        const customerData = JSON.parse(localStorage.getItem('customer'));

        if (!customerData || !customerData.access_token) {
            throw new Error('User is not authenticated or token is missing.');
        }

        const token = customerData.access_token;

        const config = {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        };

        const response = await axios.get(`${base_url}cart`, config);

        dispatch({
            type: GET_CART_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        console.error('Error:', error.response ? error.response.data : error.message);
        dispatch({
            type: GET_CART_FAILURE,
            payload: error.message,
        });
    }
};

// Add to cart
export const addToCart = (id, quantity) => async (dispatch) => {
    try {
        const customerData = JSON.parse(localStorage.getItem('customer'));

        if (!customerData || !customerData.access_token) {
            throw new Error('User is not authenticated or token is missing.');
        }

        const token = customerData.access_token;

        const config = {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        };

        const response = await axios.post(`${base_url}cart`, { product_id: id, quantity }, config);

        dispatch({
            type: ADD_TO_CART_SUCCESS,
            payload: response.data,
        });
        dispatch(getCartTotal());

        toast.success('Item added to cart successfully 🥳');
    } catch (error) {
        console.error('Error:', error.response ? error.response.data : error.message);
        dispatch({
            type: ADD_TO_CART_FAILURE,
            payload: error.message,
        });
    }
};

// Remove from cart
export const deleteFromCart = (id) => async (dispatch) => {
    try {
        const customerData = JSON.parse(localStorage.getItem('customer'));

        if (!customerData || !customerData.access_token) {
            throw new Error('User is not authenticated or token is missing.');
        }

        const token = customerData.access_token;

        const config = {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        };

        await axios.delete(`${base_url}cart/${id}`, config);

        dispatch({
            type: DELETE_FROM_CART_SUCCESS,
            payload: id,
        });
        dispatch(getCartTotal());
    } catch (error) {
        console.error('Error:', error.response ? error.response.data : error.message);
        dispatch({
            type: DELETE_FROM_CART_FAILURE,
            payload: error.message,
        });
    }
};

export const increaseQuantity = (id) => async (dispatch) => {
    try {
        const customerData = JSON.parse(localStorage.getItem('customer'));

        if (!customerData || !customerData.access_token) {
            throw new Error('User is not authenticated or token is missing.');
        }

        const token = customerData.access_token;

        const config = {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        };

        await axios.post(`${base_url}cart/increase`, { product_id: id }, config);
        dispatch({
            type: INCREASE_QUANTITY,
            payload: id,
        });
        dispatch(getCartTotal());
    } catch (error) {
        console.error('Error:', error.response ? error.response.data : error.message);
    }
};

// Decrease quantity in cart
export const decreaseQuantity = (id) => async (dispatch) => {
    try {
        const customerData = JSON.parse(localStorage.getItem('customer'));

        if (!customerData || !customerData.access_token) {
            throw new Error('User is not authenticated or token is missing.');
        }

        const token = customerData.access_token;

        const config = {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        };

        await axios.post(`${base_url}cart/decrease`, { product_id: id }, config);
        dispatch({
            type: DECREASE_QUANTITY,
            payload: id,
        });
        dispatch(getCartTotal());
    } catch (error) {
        console.error('Error:', error.response ? error.response.data : error.message);
    }
};
export const getCartTotal = () => async (dispatch) => {
    try {
        const customerData = JSON.parse(localStorage.getItem('customer'));

        if (!customerData || !customerData.access_token) {
            throw new Error('User is not authenticated or token is missing.');
        }

        const token = customerData.access_token;

        const response = await axios.get(`${base_url}cart/total`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });

        dispatch({
            type: GET_CART_TOTAL,
            payload: response.data.total_quantity,
        });
    } catch (error) {
        dispatch({
            type: CART_ERROR,
            payload: error.message,
        });
    }
};