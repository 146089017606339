import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { productService } from "./productService";

export const getAllProducts = createAsyncThunk(
    'product/getAllProducts',
    async (_, thunkAPI) => {
        try {
            const products = await productService.getProducts();
            //console.log('Fetched products:', products);
            return products;
        } catch (error) {
            console.error('Error in thunk:', error);
            return thunkAPI.rejectWithValue(error.response ? error.response.data : error.message);
        }
    }
);

export const getAProduct = createAsyncThunk(
    "product/getAProduct",
    async (id, thunkAPI) => {
        try {
            const data = await productService.getSingleProduct(id);
            // console.log('Fetched product:', data);
            return data;
        } catch (error) {
            console.error('Error in thunk:', error);
            return thunkAPI.rejectWithValue(error);
        }
    }
);


const productState = {
    product: [],
    singleproduct: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
};


export const productSlice = createSlice({
    name: "product",
    initialState: productState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllProducts.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getAllProducts.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.product = action.payload;
            })
            .addCase(getAllProducts.rejected, (state, action) => {
                state.isError = true;
                state.isLoading = false;
                state.isSuccess = false;
                state.message = action.error;
            })
            .addCase(getAProduct.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getAProduct.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isError = false;
                state.isSuccess = true;
                state.singleproduct = action.payload;
                state.message = "Product Fetched Successfully";
            })
            .addCase(getAProduct.rejected, (state, action) => {
                state.isError = true;
                state.isLoading = false;
                state.isSuccess = false;
                state.message = action.error;
            });
    },
});

export default productSlice.reducer;

