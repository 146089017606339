import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/user/userSlice";
import productReducer from "../features/product/productSlice";
import blogReducer from "../features/blog/blogSlice";
import cartReducer from "../redux/reducers/cartReducer";
import wishlistReducer from "../redux/reducers/wishListReducer";


//import contactReducer from "../features/contact/contactSlice";

export const store = configureStore({
    reducer: {
        auth: authReducer,
        product: productReducer,
        blog: blogReducer,
        cart: cartReducer,
        wishlist: wishlistReducer,
    },
});