import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { LiaSchoolSolid } from "react-icons/lia";
import { FiPaperclip } from "react-icons/fi";
import { PiDeskThin } from "react-icons/pi";
import { TbWritingSign } from "react-icons/tb";
import { IoDocumentAttachOutline } from "react-icons/io5";
import { FaAppStore } from "react-icons/fa";
import { getWishlistTotal } from "../redux/actions/wishListActon"; // Adjust the path as necessary
import { getCartTotal } from "../redux/actions/cartAction";
import { base_url } from "../utils/axiosConfig";

import axios from "axios";
import { useNavigate } from "react-router-dom";
const base_urll = "https://aback.agraphtradingplc.com/api/category";

const Header = () => {
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { user } = useSelector((state) => state.auth);

  // Toggle dropdown open state
  const handleMouseEnter = () => setIsDropdownOpen(true);
  const handleMouseLeave = () => setIsDropdownOpen(false);

  const totalQuantity = useSelector((state) => state.cart.totalQuantity);
  const totalQuantityofwish = useSelector((state) => state.wishlist.totalItems);
  const isLoggedIn =
    useSelector((state) => state.auth.isAuthenticated) ||
    Boolean(localStorage.getItem("customer"));

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getWishlistTotal());
    }
  }, [dispatch, isLoggedIn]);
  useEffect(() => {
    // Dispatch action to get the total quantity in the cart
    dispatch(getCartTotal());
  }, [dispatch]);

  const handleClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleClick1 = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchTerm(query);
  
    if (query.trim()) {
      navigate(`/shop?search=${encodeURIComponent(query.trim())}`);
    } else {
      navigate('/shop');
    }
  };
  
  
  const handleLogout = async () => {
    try {
      const token = localStorage.getItem("customer");
      await axios.post(
        `${base_url}logout`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        }
      );
      localStorage.clear();
      window.location.href = "/";
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        window.location.href = "/login"; // Redirect to login page
      } else {
        console.error("Error logging out: ", error);
      }
    }
  };
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(base_urll);
        const data = await response.json();
        setCategories(data); // Assuming data is an array of categories
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, []);
  return (
    <header className="shadow-xl font-[sans-serif] tracking-wide relative h-20 bg-gray-50 -mb-16">
      <section className="flex items-center flex-wrap lg:justify-center gap-4 py-3 sm:px-10 px-4 border-gray-200 border-b min-h-[75px] fixed top-0 left-0 w-full bg-white z-50">
        <div className="hidden md:flex md:px-4 md:py-3 md:rounded md:bg-gray-100 md:absolute  md:left-24">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 192.904 192.904"
            width="20px"
            className="cursor-pointer fill-gray-400 mr-6 rotate-90 inline-block"
          >
            <path d="m190.707 180.101-47.078-47.077c11.702-14.072 18.752-32.142 18.752-51.831C162.381 36.423 125.959 0 81.191 0 36.422 0 0 36.423 0 81.193c0 44.767 36.422 81.187 81.191 81.187 19.688 0 37.759-7.049 51.831-18.751l47.079 47.078a7.474 7.474 0 0 0 5.303 2.197 7.498 7.498 0 0 0 5.303-12.803zM15 81.193C15 44.694 44.693 15 81.191 15c36.497 0 66.189 29.694 66.189 66.193 0 36.496-29.692 66.187-66.189 66.187C44.693 147.38 15 117.689 15 81.193z"></path>
          </svg>

          <input
            type="text"
            placeholder="Search..."
            className="outline-none bg-transparent w-full text-sm"
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <button
            type="submit"
            className="absolute right-0 top-0 mt-3 mr-4"
          >
            <svg
              className="h-4 w-4 fill-current"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M12.9 14.32a8 8 0 1 1 1.41-1.41l5.35 5.33-1.42 1.42-5.33-5.34zM8 14A6 6 0 1 0 8 2a6 6 0 0 0 0 12z"></path>
            </svg>
          </button>

        </div>

        <Link to="/" className="flex shrink-0 w-16 h-16 mt-0">
          <img
            src="/assets/images/agraph.png"
            alt="logo"
            className="md:w-[170px] w-36"
          />
        </Link>

        <div className="lg:absolute lg:right-10 flex items-center ml-auto space-x-8 max-[412px]:-mt-5">
          <Link to="/wishlist" className="relative">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20px"
              className="cursor-pointer fill-[#333] hover:fill-[#007bff] inline-block size-7"
              viewBox="0 0 64 64"
            >
              <path
                d="M45.5 4A18.53 18.53 0 0 0 32 9.86 18.5 18.5 0 0 0 0 22.5C0 40.92 29.71 59 31 59.71a2 2 0 0 0 2.06 0C34.29 59 64 40.92 64 22.5A18.52 18.52 0 0 0 45.5 4ZM32 55.64C26.83 52.34 4 36.92 4 22.5a14.5 14.5 0 0 1 26.36-8.33 2 2 0 0 0 3.27 0A14.5 14.5 0 0 1 60 22.5c0 14.41-22.83 29.83-28 33.14Z"
                data-original="#000000"
              />
            </svg>
            <span className="absolute left-auto -ml-1 top-0 rounded-full bg-red-600 px-1 py-0 text-xs text-white">
              {" "}
              {totalQuantityofwish}
            </span>
          </Link>
          <Link to="/cart" className="relative">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20px"
              height="20px"
              className="cursor-pointer fill-[#333] hover:fill-[#007bff] inline-block size-7"
              viewBox="0 0 512 512"
            >
              <path
                d="M164.96 300.004h.024c.02 0 .04-.004.059-.004H437a15.003 15.003 0 0 0 14.422-10.879l60-210a15.003 15.003 0 0 0-2.445-13.152A15.006 15.006 0 0 0 497 60H130.367l-10.722-48.254A15.003 15.003 0 0 0 105 0H15C6.715 0 0 6.715 0 15s6.715 15 15 15h77.969c1.898 8.55 51.312 230.918 54.156 243.71C131.184 280.64 120 296.536 120 315c0 24.812 20.188 45 45 45h272c8.285 0 15-6.715 15-15s-6.715-15-15-15H165c-8.27 0-15-6.73-15-15 0-8.258 6.707-14.977 14.96-14.996zM477.114 90l-51.43 180H177.032l-40-180zM150 405c0 24.813 20.188 45 45 45s45-20.188 45-45-20.188-45-45-45-45 20.188-45 45zm45-15c8.27 0 15 6.73 15 15s-6.73 15-15 15-15-6.73-15-15 6.73-15 15-15zm167 15c0 24.813 20.188 45 45 45s45-20.188 45-45-20.188-45-45-45-45 20.188-45 45zm45-15c8.27 0 15 6.73 15 15s-6.73 15-15 15-15-6.73-15-15 6.73-15 15-15zm0 0"
                data-original="#000000"
              ></path>
            </svg>
            <span className="absolute left-auto -ml-1 top-0 rounded-full bg-red-600 px-1 py-0 text-xs text-white">
              {totalQuantity}
            </span>
          </Link>
          <div className="inline-block cursor-pointer border-gray-300">
            <div className="relative font-[sans-serif] w-max mx-auto">
              <button
                type="button"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={handleClick1}
                className="px-4 py-2 flex items-center rounded-full text-[#333] text-sm border border-gray-300 outline-none hover:bg-gray-100"
              >
                <img
                  src="https://readymadeui.com/profile_6.webp"
                  className="w-7 h-7 mr-3 rounded-full shrink-0"
                ></img>

              </button>

              {isDropdownOpen && (
                <ul
                  onMouseEnter={handleMouseEnter} // Keep dropdown open when hovering over it
                  onMouseLeave={handleMouseLeave}
                  className="max-[500px]:-right-3 min-[501px]:right-1 absolute block bg-gray-100 shadow-xl py-2 z-[1000] min-w-full w-max rounded-lg max-h-96 overflow-auto"
                >
                  {!isLoggedIn && (
                    <ul>
                      <li className="py-2.5 px-5 flex items-center hover:text-red-600 hover:bg-green-200 hover:rounded-md text-[#333] text-sm cursor-pointer">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          className="w-4 h-4 mr-3"
                          viewBox="0 0 512 512"
                        >
                          <path
                            d="M197.332 170.668h-160C16.746 170.668 0 153.922 0 133.332v-96C0 16.746 16.746 0 37.332 0h160c20.59 0 37.336 16.746 37.336 37.332v96c0 20.59-16.746 37.336-37.336 37.336zM37.332 32A5.336 5.336 0 0 0 32 37.332v96a5.337 5.337 0 0 0 5.332 5.336h160a5.338 5.338 0 0 0 5.336-5.336v-96A5.337 5.337 0 0 0 197.332 32zm160 480h-160C16.746 512 0 495.254 0 474.668v-224c0-20.59 16.746-37.336 37.332-37.336h160c20.59 0 37.336 16.746 37.336 37.336v224c0 20.586-16.746 37.332-37.336 37.332zm-160-266.668A5.337 5.337 0 0 0 32 250.668v224A5.336 5.336 0 0 0 37.332 480h160a5.337 5.337 0 0 0 5.336-5.332v-224a5.338 5.338 0 0 0-5.336-5.336zM474.668 512h-160c-20.59 0-37.336-16.746-37.336-37.332v-96c0-20.59 16.746-37.336 37.336-37.336h160c20.586 0 37.332 16.746 37.332 37.336v96C512 495.254 495.254 512 474.668 512zm-160-138.668a5.338 5.338 0 0 0-5.336 5.336v96a5.337 5.337 0 0 0 5.336 5.332h160a5.336 5.336 0 0 0 5.332-5.332v-96a5.337 5.337 0 0 0-5.332-5.336zm160-74.664h-160c-20.59 0-37.336-16.746-37.336-37.336v-224C277.332 16.746 294.078 0 314.668 0h160C495.254 0 512 16.746 512 37.332v224c0 20.59-16.746 37.336-37.332 37.336zM314.668 32a5.337 5.337 0 0 0-5.336 5.332v224a5.338 5.338 0 0 0 5.336 5.336h160a5.337 5.337 0 0 0 5.332-5.336v-224A5.336 5.336 0 0 0 474.668 32zm0 0"
                            data-original="#000000"
                          ></path>
                        </svg>
                        <Link to="/login">sign In</Link>
                      </li>
                      <li className="py-2.5 px-5 flex items-center hover:text-red-600 hover:bg-green-200 hover:rounded-md text-[#333] text-sm cursor-pointer">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          className="w-4 h-4 mr-3"
                          viewBox="0 0 512 512"
                        >
                          <path
                            d="M197.332 170.668h-160C16.746 170.668 0 153.922 0 133.332v-96C0 16.746 16.746 0 37.332 0h160c20.59 0 37.336 16.746 37.336 37.332v96c0 20.59-16.746 37.336-37.336 37.336zM37.332 32A5.336 5.336 0 0 0 32 37.332v96a5.337 5.337 0 0 0 5.332 5.336h160a5.338 5.338 0 0 0 5.336-5.336v-96A5.337 5.337 0 0 0 197.332 32zm160 480h-160C16.746 512 0 495.254 0 474.668v-224c0-20.59 16.746-37.336 37.332-37.336h160c20.59 0 37.336 16.746 37.336 37.336v224c0 20.586-16.746 37.332-37.336 37.332zm-160-266.668A5.337 5.337 0 0 0 32 250.668v224A5.336 5.336 0 0 0 37.332 480h160a5.337 5.337 0 0 0 5.336-5.332v-224a5.338 5.338 0 0 0-5.336-5.336zM474.668 512h-160c-20.59 0-37.336-16.746-37.336-37.332v-96c0-20.59 16.746-37.336 37.336-37.336h160c20.586 0 37.332 16.746 37.332 37.336v96C512 495.254 495.254 512 474.668 512zm-160-138.668a5.338 5.338 0 0 0-5.336 5.336v96a5.337 5.337 0 0 0 5.336 5.332h160a5.336 5.336 0 0 0 5.332-5.332v-96a5.337 5.337 0 0 0-5.332-5.336zm160-74.664h-160c-20.59 0-37.336-16.746-37.336-37.336v-224C277.332 16.746 294.078 0 314.668 0h160C495.254 0 512 16.746 512 37.332v224c0 20.59-16.746 37.336-37.332 37.336zM314.668 32a5.337 5.337 0 0 0-5.336 5.332v224a5.338 5.338 0 0 0 5.336 5.336h160a5.337 5.337 0 0 0 5.332-5.336v-224A5.336 5.336 0 0 0 474.668 32zm0 0"
                            data-original="#000000"
                          ></path>
                        </svg>
                        <Link to="/register">sign up</Link>
                      </li>
                    </ul>
                  )}
                  {user && <li className="text-center"> Hi ✋</li>}
                  <li
                    onClick={handleLogout}
                    className="py-2.5 px-5 flex items-center hover:text-red-600 hover:bg-green-200 hover:rounded-md text-[#333] text-sm cursor-pointer"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      className="w-4 h-4 mr-3"
                      viewBox="0 0 6.35 6.35"
                    >
                      <path
                        d="M3.172.53a.265.266 0 0 0-.262.268v2.127a.265.266 0 0 0 .53 0V.798A.265.266 0 0 0 3.172.53zm1.544.532a.265.266 0 0 0-.026 0 .265.266 0 0 0-.147.47c.459.391.749.973.749 1.626 0 1.18-.944 2.131-2.116 2.131A2.12 2.12 0 0 1 1.06 3.16c0-.65.286-1.228.74-1.62a.265.266 0 1 0-.344-.404A2.667 2.667 0 0 0 .53 3.158a2.66 2.66 0 0 0 2.647 2.663 2.657 2.657 0 0 0 2.645-2.663c0-.812-.363-1.542-.936-2.03a.265.266 0 0 0-.17-.066z"
                        data-original="#000000"
                      ></path>
                    </svg>
                    Logout
                  </li>
                </ul>
              )}
            </div>
          </div>
        </div>
      </section>

      <div className="flex flex-wrap justify-center px-10 py-3 relative mt-24">
        <div
          id="collapseMenu"
          className={`lg:flex ${isMenuOpen ? "block" : "hidden"
            } max-md:fixed max-lg:bg-white max-lg:w-2/3 max-lg:min-w-[300px] max-lg:top-0 max-lg:left-0 max-lg:p-4 max-lg:h-full max-lg:shadow-md max-lg:overflow-auto z-50`}
        >
          <button
            id="toggleClose"
            onClick={handleClick}
            className="lg:hidden fixed top-2 right-4 z-[100] rounded-full bg-gray-800 p-3 "
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-4 fill-white"
              viewBox="0 0 320.591 320.591"
            >
              <path
                d="M30.391 318.583a30.37 30.37 0 0 1-21.56-7.288c-11.774-11.844-11.774-30.973 0-42.817L266.643 10.665c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875L51.647 311.295a30.366 30.366 0 0 1-21.256 7.288z"
                data-original="#000000"
              ></path>
              <path
                d="M287.9 318.583a30.37 30.37 0 0 1-21.257-8.806L8.83 51.963C-2.078 39.225-.595 20.055 12.143 9.146c11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414a30.368 30.368 0 0 1-23.078 7.288z"
                data-original="#000000"
              ></path>
            </svg>
          </button>

          <ul className="lg:flex lg:gap-x-10 max-lg:space-y-3 max-lg:fixed max-lg:bg-white max-lg:w-2/3 max-lg:min-w-[300px] max-lg:top-0 max-lg:left-0 max-lg:p-4 max-lg:h-full max-lg:shadow-md max-lg:overflow-auto">
            <li className="max-lg:border-b max-lg:pb-4 px-3 lg:hidden">
              <Link to="" className="flex ">
                <img
                  src="/assets/images/agraph.png"
                  alt="logo"
                  className="w-32 pl-10"
                />
              </Link>
            </li>
            <li className="md:hidden lg:hidden xl:hidden max-lg:border-b max-lg:px-3 max-lg:py-3">
              <div className=" flex">
                <div className="relative">
                  <input
                    type="text"
                    className="bg-gray-200 h-10 px-5 pr-10 rounded-full text-sm focus:outline-none transition-all duration-300 ease-in-out w-10 focus:w-44"
                    placeholder="Search..."
                  />
                  <button
                    type="submit"
                    className="absolute right-0 top-0 mt-3 mr-4"
                  >
                    <svg
                      className="h-4 w-4 fill-current"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M12.9 14.32a8 8 0 1 1 1.41-1.41l5.35 5.33-1.42 1.42-5.33-5.34zM8 14A6 6 0 1 0 8 2a6 6 0 0 0 0 12z"></path>
                    </svg>
                  </button>
                </div>
              </div>
            </li>
            <li className="max-lg:border-b max-lg:px-3 max-lg:py-3">
              <Link
                to=""
                className="hover:text-[#007bff] text-[#007bff] font-semibold block text-[15px]"
              >
                Home
              </Link>
            </li>
            <li className='group max-lg:border-b max-lg:px-3 max-lg:py-3 relative'>
              <Link to="/shop"
                className='flex hover:text-[#007bff] hover:fill-[#007bff] text-gray-600 font-semibold text-[15px]'>Categories<svg
                  xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" className="ml-1 inline-block"
                  viewBox="0 0 24 24">
                  <path
                    d="M12 16a1 1 0 0 1-.71-.29l-6-6a1 1 0 0 1 1.42-1.42l5.29 5.3 5.29-5.29a1 1 0 0 1 1.41 1.41l-6 6a1 1 0 0 1-.7.29z"
                    data-name="16" data-original="#000000" />
                </svg>
              </Link>
              <ul className='absolute top-5 max-lg:top-8 left-0 z-50 block space-y-2 shadow-lg bg-white max-h-0 overflow-hidden min-w-[250px] group-hover:opacity-100 group-hover:max-h-[700px] px-6 group-hover:pb-4 group-hover:pt-6 transition-all duration-500'>
      {/* All Store option */}
      <li className='border-b py-3'>
        <Link
          to={`/shop`}
          className='flex gap-3 hover:text-[#007bff] hover:fill-[#007bff] text-gray-600 font-semibold text-[15px]'
        >
          <FaAppStore size={30} />
          All Store
        </Link>
      </li>

      {/* Map through categories from backend */}
      {categories.map((category) => (
  <li key={category.id} className='border-b py-3'>
    <Link
      to={`/shop?category=${category.title}`}
      className='flex gap-3 hover:text-[#007bff] hover:fill-[#007bff] text-gray-600 font-semibold text-[15px]'>
      {getIconByCategory(category.title)}
      {category.title}
    </Link>
  </li>
))}

    </ul>
            </li>
            <li className="max-lg:border-b max-lg:px-3 max-lg:py-3">
              <Link
                to="/Categories"
                className="hover:text-[#007bff] text-gray-600 font-semibold text-[15px] block"
              >
                Shops
              </Link>
            </li>

            <li className="max-lg:border-b max-lg:px-3 max-lg:py-3">
              <Link
                to="/blog"
                className="hover:text-[#007bff] text-gray-600 font-semibold text-[15px] block"
              >
                Blog
              </Link>
            </li>
            <li className="max-lg:border-b max-lg:px-3 max-lg:py-3">
              <Link
                to="/about"
                className="hover:text-[#007bff] text-gray-600 font-semibold text-[15px] block"
              >
                About
              </Link>
            </li>
            <li className="max-lg:border-b max-lg:px-3 max-lg:py-3">
              <Link
                to="/contact"
                className="hover:text-[#007bff] text-gray-600 font-semibold text-[15px] block"
              >
                Contact
              </Link>
            </li>
            <li className="max-lg:border-b max-lg:px-3 max-lg:py-3">
              <Link
                to="/product/new/coming"
                className="hover:text-[#007bff] text-gray-600 font-semibold text-[15px] block"
              >
                New Arrival
              </Link>
            </li>
          </ul>
        </div>
        <div onClick={handleClick} className="flex ml-auto lg:hidden">
          <button>
            {isMenuOpen ? (
              <svg
                className="w-7 h-7"
                fill="#000"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M6 18L18 6M6 6l12 12"
                  clipRule="evenodd"
                ></path>
              </svg>
            ) : (
              <svg
                className="w-7 h-7"
                fill="#000"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clipRule="evenodd"
                ></path>
              </svg>
            )}
          </button>
        </div>
      </div>
    </header>
  );
};
const getIconByCategory = (categoryName) => {
  switch (categoryName.toLowerCase()) {
    case 'document accessories':
      return <IoDocumentAttachOutline size={30} />;
    case 'school accessories':
      return <LiaSchoolSolid size={30} />;
    case 'paper accessories':
      return <FiPaperclip size={30} />;
    case 'desk accessories':
      return <PiDeskThin size={30} />;
    case 'writing accessories':
      return <TbWritingSign size={30} />;
    case 'all store':
      return <FaAppStore size={30} />;
    default:
      return <IoDocumentAttachOutline size={30} />; // Default icon
  }
};
export default Header;
