import axios from "axios";
import { base_url, } from "../../utils/axiosConfig";
const getProducts = async () => {
    try {
        const response = await axios.get(`${base_url}pro`);
        if (response.data) {
            //console.log('Product data fetched:', response.data); // Add logging
            return response.data;
        }
    } catch (error) {
        console.error('Error fetching products:', error); // Add error logging
        throw error;
    }

};

const getSingleProduct = async (id) => {
    console.log('API request for product ID:', id);
    try {
        const response = await axios.get(`${base_url}pro/${id}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching product details:', error);
        throw error;
    }
};



export const productService = {
    getProducts,
    getSingleProduct,
};