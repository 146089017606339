import "./Loader.css";

const Loader = () => {
    return (

        <div className="loader">
            Agraph
            <span></span>
        </div>


    )
}

export default Loader