import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./Components/Header";
import Loader from "./Components/Loader";
import { lazy, Suspense, useState, useEffect } from "react";

// Lazy load components
import ProtectedRoute from "./features/ProtectedRoute.jsx"; // Import ProtectedRoute
const Home = lazy(() => import("./Components/Home"));
const Checkout = lazy(() => import("./Components/Checkout"));
const Shops = lazy(() => import("./Components/Shops"));
const Categories = lazy(() => import("./Components/Catagories"));
const Blog = lazy(() => import("./Components/Blog"));
const BlogDetail = lazy(() => import("./Components/BlogDetails.jsx"));
const Contact = lazy(() => import("./Components/Contact"));
const About = lazy(() => import("./Components/About"));
const Cart = lazy(() => import("./Components/Cart"));
const Wishlist = lazy(() => import("./Components/Wishlist"));
const Pdetail = lazy(() => import("./Components/ProductDetails"));
const Login = lazy(() => import("./Components/Login"));
const Register = lazy(() => import("./Components/Register"));
const PaymentSuccess = lazy(() => import("./Components/SuccessPayment"));
const Temp1 = lazy(() => import("./Components/AllProducts"));
const HotDeals = lazy(() => import("./Components/ShopProducts"));
const NewIncoming = lazy(() => import("./Components/NewIncoming.jsx"));

const App = () => {
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoader(false);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Router>
      <div className="app-container">
        {showLoader && <Loader />}
        {!showLoader && (
          <>
            <Header />
            <main>
              <Suspense fallback={<Loader />}>
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/checkout" element={<Checkout />} />

                  <Route
                    path="/shop"
                    element={
                      <ProtectedRoute>
                        <Shops />
                      </ProtectedRoute>
                    }
                  />
                  <Route path="/categories" element={<Categories />} />
                  <Route path="/blog" element={<Blog />} />
                  <Route path="/blog/:id" element={<BlogDetail />} />
                  <Route path="/contact" element={<Contact />} />
                  <Route path="/about" element={<About />} />
                  <Route path="/cart" element={<Cart />} />
                  <Route path="/wishlist" element={<Wishlist />} />
                  <Route path="/product/product-detail/:id" element={<Pdetail />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/register" element={<Register />} />
                  <Route path="/product/product-categories" element={<HotDeals />} />
                  <Route path="/payment/success" element={<PaymentSuccess />} />
                  <Route path="/temp1" element={<Temp1 />} />
                  <Route path="/product/new/coming" element={<NewIncoming />} />
                </Routes>
              </Suspense>
            </main>
          </>
        )}
      </div>
    </Router>
  );
};

export default App;
