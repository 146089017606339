const initialState = {
    items: [],
    totalItems: 0,
    loading: false,
    error: null,
};

const wishlistReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_WISHLIST_SUCCESS':
            return {
                ...state,
                items: action.payload,
                totalItems: action.payload.length,
                loading: false,
                error: null,
            };
        case 'GET_WISHLIST_FAILURE':
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case 'ADD_TO_WISHLIST_SUCCESS':
            return {
                ...state,
                items: [...state.items, action.payload],
                totalItems: state.items.length + 1,
            };
        case 'DELETE_FROM_WISHLIST_SUCCESS':
            return {
                ...state,
                items: state.items.filter((item) => item.id !== action.payload),
                totalItems: state.totalItems - 1,
            };
        case 'DELETE_FROM_WISHLIST_FAILURE':
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case 'GET_WISHLIST_TOTAL_SUCCESS':
            return {
                ...state,
                totalItems: action.payload,
            };
        case 'GET_WISHLIST_TOTAL_FAILURE':
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default wishlistReducer;
